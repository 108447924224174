<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>발주관리 상세</h1></div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2 >발주정보 <common-checkbox-yn
            class="mgl10"
            style="display:inline-block"
            :disabled="true"
            v-model.trim="ordInfo.adjtYn"
            :label="'정산필요'"
        ></common-checkbox-yn>
        </h2></div>
      <ul class="filter_list">
        <li>
          <dd><label>회사명</label>
            <ul>
              <li>{{ordInfo.cmpyNm}}</li>
            </ul>
          </dd>
          <dd ><label>회사코드</label>
            <ul>
              <li>{{ ordInfo.cmpyCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>납품로케이션</label>
            <ul>
              <li>{{ordInfo.locaNm}}</li>
            </ul>
          </dd>
          <dd ><label>발주일자</label>
            <ul>
              <li>{{ ordInfo.ordDt}}</li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>

    <div class="contents_wrap">
      <div class="small_title_wrap">
      <h2>입고예정 정보</h2>
    </div>
      <common-grid-custom
          class="mt20"
          ref="userMngGridRef"
          :class="'mgt20'"
          :scrollable="false"
          :data-source="inScheduleList"
          :columns="inScheduleGridColumns"
      >
        <template #ordNos="{rowItem}">
          {{ rowItem.data.ordNos }}차수
        </template>
        <template #supCnt="{rowItem}">
          {{ rowItem.data.supCnt | comma }} EA
        </template>
        <template #supPrice="{rowItem}">
          {{ rowItem.data.supPrice | comma }} 원
        </template>
        <template #supTotPrice="{rowItem}">
          {{ rowItem.data.supTotPrice | comma }} 원
        </template>

      </common-grid-custom>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>공급업체정보</h2>
      </div>
      <common-grid-custom
          ref="userMngGridRef"
          :class="'mgt20'"
          :sortable="false"
          :scrollable="true"
          :sortable-mode="'multiple'"
          :data-source="supCmpyUserList"
          :columns="supCmpyUserGridColumns"
          :selectable="'row'"
      >
        <template #radio="{rowItem}">
          <radio-group
              :name="'recevEmail'"
              v-model="ordInfo.supMngUnqUserId"
              :disabled="true"
              :data-items="[{value : rowItem.data.supMngUnqUserId, label : ''}]"
          ></radio-group>
        </template>

      </common-grid-custom>
    </div>

    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>발주내역</h2>
      </div>
      <common-grid-custom
          ref="ordHistoryGridRef"
          :class="'mgt20'"
          :data-source="ordHistoryList"
          :columns="ordHistoryGridColumns"
          :selectable="'row'"
      >
        <template #saleTotPrice="{rowItem}">
          {{ rowItem.data.saleTotPrice | comma }} 원
        </template>
        <template #preview="{rowItem}">
          <button @click="getOrdPurchaseOrder(rowItem)" class="mid_btn">보기</button>
        </template>
        <template #send="{rowItem}">
          <button v-if="rowItem.index === 0 "
                  @click="sendOrdMail(rowItem.data.ordMailNos)"
                  class="mid_btn orange">발주서발송</button>
        </template>

      </common-grid-custom>

    </div>

    <!--- 버튼영역 --->
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button class="large_btn"
                  @click="$router.push({ name: 'OrdManageList' })">목록</button>
        </li>
        <li class="w30">
          <button class="large_btn dgray"
                  @click="onClickDeleteOrd">삭제</button>
        </li>
        <li class="w30">
          <button class="large_btn orange"
                  @click="$router.push({name: 'OrdManageEdit' })">수정</button>
        </li>
      </ul>
    </div>

    <kendo-window
        ref="searchComplexWindowRef"
        :title="'발주서'"
        :modal="true"
        style="display:none; min-height: 750px; width:850px;"
        @open="e=>{this.isWindowOpen = true}"
        @close="e=>{this.isWindowOpen = false}"
    >
      <span v-html="temp" ></span>
    </kendo-window>
  </div>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'

export default {
  name   : 'OrdManageDetail',
  created () {
    const vm = this
    vm.getOrdInfo()
  },
  methods : {
    getOrdPurchaseOrder: function (rowData) {
      const vm = this

      let param = {
        ordNo : rowData.data.ordNo,
        ordMailNos : rowData.data.ordMailNos
      }

      ApiUtil.query(
          `${ApiConfig.efsDomain}/efs/manage/ord/purchase-order`, param)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.temp = response.data.resultData
              vm.$refs.searchComplexWindowRef.kendoWidget().center().open()
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    getOrdInfo : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/ord/${vm.$route.params.ordNo}`)
      .then(function (response) {
          if (response.data.resultStatus.messageCode === '2000') {
            vm.ordInfo = response.data.resultData
            vm.inScheduleList = response.data.resultData.ordDetailItemResponseList
            vm.inScheduleList.forEach((item, i)=>{
              if(item.useYn === 'N') {
                item.rowClass = 'bg_g '
                item.inStatusNm = '제외'
              }
            })
            vm.ordHistoryList = response.data.resultData.ordPurchaseOrderMailHisList
            vm.getGoodSupCmpyUser(vm.inScheduleList[0].goodsCd)
          } else {
            kendo.alert(response.data.resultStatus.messageText)
          }
        },
      )
    },
    getGoodSupCmpyUser : function (goodsCd){
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/ord/goods-cmpy`, {goodsCd})
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.supCmpyUserList = response.data.resultData
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    sendOrdMail : function (ordMailNos){
      const vm = this
      kendo.confirm(`${vm.ordInfo.supCmpyNm} ${vm.ordInfo.supMngUnqUserNm}님에게 발주서를 발송하시겠습니까?<br>(${vm.ordInfo.supMngUnqUserEmail})`)
      .done(function (){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/manage/ord/send-mail`, {ordNo : vm.$route.params.ordNo , ordMailNos : ordMailNos})
        .then(function (response) {
              if (response.data.resultStatus.messageCode === '2000') {
                kendo.alert("발주서가 발송되었습니다.")
                vm.getOrdInfo()
              } else {
                kendo.alert(response.data.resultStatus.messageText)
              }
            },
        )
      })

    },
    onClickDeleteOrd : function() {
      const vm = this
      kendo.confirm('발주를 삭제하시겠습니까?').done(function (){
        vm.deleteOrd()
      })
    },
    deleteOrd : function (){
      const vm = this
      ApiUtil.delete(`${ApiConfig.efsDomain}/efs/manage/ord/${vm.$route.params.ordNo}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              kendo.alert('삭제되었습니다.').bind('close', function() {
                vm.$router.push({ name: 'OrdManageList' })
              })
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    }
  },
    data   : function () {
      const vm = this
      const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
      const rightAttributes = { style: 'text-align: right;' }
      const centerAttributes = { style: 'text-align: center;' }
      return {
        isWindowOpen : false,
        ApiConfig : { fmsDomain : ApiConfig.fmsDomain },
        ordInfo  : {},
        inScheduleList : [],
        supCmpyUserList : [],
        ordHistoryList : [],
        temp : ``,
        inScheduleGridColumns : [
          {
            field: 'itemNm',
            title: '품목',
            width: '15%',
            headerAttributes: headerAttributes,
            attributes: { class:'txt_l' }
          },
          {
            field: 'goodsNm',
            title: '상품명',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: { class:'txt_l' }
          },
          {
            field: 'ordNos',
            title: '입고차수',
            width: '6%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'inExpectDate',
            title: '입고예정일',
            width: '6%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'supCnt',
            title: '수량',
            width: '6%',
            headerAttributes: headerAttributes,
          attributes: { class:'txt_r' }
          },
          {
            field: 'supPrice',
            title: '공급가',
            width: '6%',
            headerAttributes: headerAttributes,
          attributes: { class:'txt_r' }
          },
          {
            field: 'supTotPrice',
            title: '합계',
            width: '6%',
            headerAttributes: headerAttributes,
          attributes: { class:'txt_r' }
          },
          {
            field: 'inStatusNm',
            title: '입고상태',
            width: '6%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'contents',
            title: '비고',
            width: '6%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
        ],
        supCmpyUserGridColumns : [
          {
            field: 'supCmpyNm',
            title: '공급사',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'supMngUnqUserNm',
            title: '성명',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'supMngUnqUserPsnScnNm',
            title: '직위',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'supMngUnqUserTelNo',
            title: '연락처',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'supMngUnqUserEmail',
            title: '이메일',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'radio',
            title: '발주서 수신자',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
        ],
        ordHistoryGridColumns : [
          {
            field: 'supCmpyNm',
            title: '공급사',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'supCmpyMngUnqUserNm',
            title: '공급사 담당자',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'recvFullAddr',
            title: '수령 주소',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'saleTotPrice',
            title: '합계',
            width: '10%',
            attributes: rightAttributes,
            headerAttributes: headerAttributes
          },
          {
            field: 'sendYn',
            title: '발주서 발송 여부',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'sendDt',
            title: '발주서 발송일시',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'preview',
            title: '미리보기',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'send',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
        ],
      }
    },
  }
</script>
