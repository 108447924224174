var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gnb_pdt",attrs:{"id":"container"}},[_vm._m(0),_c('div',{staticClass:"contents_wrap"},[_c('div',{staticClass:"small_title_wrap"},[_c('h2',[_vm._v("발주정보 "),_c('common-checkbox-yn',{staticClass:"mgl10",staticStyle:{"display":"inline-block"},attrs:{"disabled":true,"label":'정산필요'},model:{value:(_vm.ordInfo.adjtYn),callback:function ($$v) {_vm.$set(_vm.ordInfo, "adjtYn", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"ordInfo.adjtYn"}})],1)]),_c('ul',{staticClass:"filter_list"},[_c('li',[_c('dd',[_c('label',[_vm._v("회사명")]),_c('ul',[_c('li',[_vm._v(_vm._s(_vm.ordInfo.cmpyNm))])])]),_c('dd',[_c('label',[_vm._v("회사코드")]),_c('ul',[_c('li',[_vm._v(_vm._s(_vm.ordInfo.cmpyCd))])])])]),_c('li',[_c('dd',[_c('label',[_vm._v("납품로케이션")]),_c('ul',[_c('li',[_vm._v(_vm._s(_vm.ordInfo.locaNm))])])]),_c('dd',[_c('label',[_vm._v("발주일자")]),_c('ul',[_c('li',[_vm._v(_vm._s(_vm.ordInfo.ordDt))])])])])])]),_c('div',{staticClass:"contents_wrap"},[_vm._m(1),_c('common-grid-custom',{ref:"userMngGridRef",staticClass:"mt20",class:'mgt20',attrs:{"scrollable":false,"data-source":_vm.inScheduleList,"columns":_vm.inScheduleGridColumns},scopedSlots:_vm._u([{key:"ordNos",fn:function(ref){
var rowItem = ref.rowItem;
return [_vm._v(" "+_vm._s(rowItem.data.ordNos)+"차수 ")]}},{key:"supCnt",fn:function(ref){
var rowItem = ref.rowItem;
return [_vm._v(" "+_vm._s(_vm._f("comma")(rowItem.data.supCnt))+" EA ")]}},{key:"supPrice",fn:function(ref){
var rowItem = ref.rowItem;
return [_vm._v(" "+_vm._s(_vm._f("comma")(rowItem.data.supPrice))+" 원 ")]}},{key:"supTotPrice",fn:function(ref){
var rowItem = ref.rowItem;
return [_vm._v(" "+_vm._s(_vm._f("comma")(rowItem.data.supTotPrice))+" 원 ")]}}])})],1),_c('div',{staticClass:"contents_wrap"},[_vm._m(2),_c('common-grid-custom',{ref:"userMngGridRef",class:'mgt20',attrs:{"sortable":false,"scrollable":true,"sortable-mode":'multiple',"data-source":_vm.supCmpyUserList,"columns":_vm.supCmpyUserGridColumns,"selectable":'row'},scopedSlots:_vm._u([{key:"radio",fn:function(ref){
var rowItem = ref.rowItem;
return [_c('radio-group',{attrs:{"name":'recevEmail',"disabled":true,"data-items":[{value : rowItem.data.supMngUnqUserId, label : ''}]},model:{value:(_vm.ordInfo.supMngUnqUserId),callback:function ($$v) {_vm.$set(_vm.ordInfo, "supMngUnqUserId", $$v)},expression:"ordInfo.supMngUnqUserId"}})]}}])})],1),_c('div',{staticClass:"contents_wrap"},[_vm._m(3),_c('common-grid-custom',{ref:"ordHistoryGridRef",class:'mgt20',attrs:{"data-source":_vm.ordHistoryList,"columns":_vm.ordHistoryGridColumns,"selectable":'row'},scopedSlots:_vm._u([{key:"saleTotPrice",fn:function(ref){
var rowItem = ref.rowItem;
return [_vm._v(" "+_vm._s(_vm._f("comma")(rowItem.data.saleTotPrice))+" 원 ")]}},{key:"preview",fn:function(ref){
var rowItem = ref.rowItem;
return [_c('button',{staticClass:"mid_btn",on:{"click":function($event){return _vm.getOrdPurchaseOrder(rowItem)}}},[_vm._v("보기")])]}},{key:"send",fn:function(ref){
var rowItem = ref.rowItem;
return [(rowItem.index === 0 )?_c('button',{staticClass:"mid_btn orange",on:{"click":function($event){return _vm.sendOrdMail(rowItem.data.ordMailNos)}}},[_vm._v("발주서발송")]):_vm._e()]}}])})],1),_c('div',{staticClass:"button_area w25"},[_c('ul',[_c('li',{staticClass:"w30"},[_c('button',{staticClass:"large_btn",on:{"click":function($event){return _vm.$router.push({ name: 'OrdManageList' })}}},[_vm._v("목록")])]),_c('li',{staticClass:"w30"},[_c('button',{staticClass:"large_btn dgray",on:{"click":_vm.onClickDeleteOrd}},[_vm._v("삭제")])]),_c('li',{staticClass:"w30"},[_c('button',{staticClass:"large_btn orange",on:{"click":function($event){return _vm.$router.push({name: 'OrdManageEdit' })}}},[_vm._v("수정")])])])]),_c('kendo-window',{ref:"searchComplexWindowRef",staticStyle:{"display":"none","min-height":"750px","width":"850px"},attrs:{"title":'발주서',"modal":true},on:{"open":function (e){this$1.isWindowOpen = true},"close":function (e){this$1.isWindowOpen = false}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.temp)}})])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title_wrap"},[_c('h1',[_vm._v("발주관리 상세")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small_title_wrap"},[_c('h2',[_vm._v("입고예정 정보")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small_title_wrap"},[_c('h2',[_vm._v("공급업체정보")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small_title_wrap"},[_c('h2',[_vm._v("발주내역")])])}]

export { render, staticRenderFns }